import { IconButton, Tooltip } from '@mui/material';
import useLogic from './logic';
import * as S from './style';
import type { Props } from './types';
import { MaterialSymbol } from 'react-material-symbols';
import AccountMenu from './Menu';
import Generate from '../../../components/Generate';
import { GenerateController } from '../../../components/Generate/types';
import { useRef } from 'react';

const routes = [
    { name: 'Generate', path: '/stacks' },
    { name: 'Subscriptions', path: '/subscriptions' },
    { name: 'Bots', path: '/bots', icon: 'smart_toy' },
    { name: 'Documentation', path: 'https://docs.hautech.ai' },
];

const Header = (props: Props) => {
    const { onClickLogout, navigate, user } = useLogic(props);
    const controllerRef = useRef<GenerateController>({ open: () => {} });

    return (
        <S.Container>
            <S.LogoContainer>
                <S.Logo onClick={() => navigate('/')} src="/logo_black.svg"></S.Logo>
            </S.LogoContainer>
            <S.GenerateContainer>
                {props.showGenerateBar && <Generate controllerRef={controllerRef} />}
            </S.GenerateContainer>
            <S.RightContainer>
                {!!user && <AccountMenu name={user.name ?? ''} avatar={user.picture ?? ''} />}
            </S.RightContainer>
        </S.Container>
    );
};

export default Header;
