import { useCallback } from 'react';
import type { Props } from './types';

const useLogic = (props: Props) => {
    const onChangeAge = useCallback((age: string) => props.onChangeState({ age }), []);
    const onChangeAngle = useCallback((angle: string) => props.onChangeState({ angle }), []);
    const onChangeAspectRatio = useCallback((aspectRatio: string) => props.onChangeState({ aspectRatio }), []);
    const onChangeBackground = useCallback((background?: string) => props.onChangeState({ background }), []);
    const onChangeModel = useCallback((model?: string) => props.onChangeState({ model }), []);
    const onChangeFile = useCallback((file: File) => props.onChangeState({ file }), []);
    const onChangeGender = useCallback((gender: string) => props.onChangeState({ gender }), []);
    const onChangeHairColor = useCallback((hairColor: string) => props.onChangeState({ hairColor }), []);
    const onChangePhotoType = useCallback((photoType: string) => props.onChangeState({ photoType }), []);
    const onChangePrompt = useCallback((event: any) => props.onChangeState({ prompt: event.target.value }), []);
    const onChangeSeed = useCallback((event: any) => {
        const seed = parseInt(event.target.value);
        props.onChangeState({ seed });
    }, []);
    const onChangeSkinTone = useCallback((skinTone: string) => props.onChangeState({ skinTone }), []);
    const onChangeVersion = useCallback((version: string) => props.onChangeState({ version }), []);

    return {
        onChangeAge,
        onChangeAngle,
        onChangeAspectRatio,
        onChangeBackground,
        onChangeModel,
        onChangeFile,
        onChangeGender,
        onChangeHairColor,
        onChangePhotoType,
        onChangePrompt,
        onChangeSeed,
        onChangeSkinTone,
        onChangeVersion,
    };
};

export default useLogic;
