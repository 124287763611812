import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';

const ActionsListButton = (props: Props) => {
    const { anchor, menuOpen, onClickAction, onCloseMenu, onOpenMenu } = useLogic(props);
    return (
        <>
            <Button onClick={onOpenMenu} variant="outlined">
                {props.name}
            </Button>
            <Menu anchorEl={anchor} open={menuOpen} onClose={onCloseMenu}>
                {props.actions.map((action) => (
                    <MenuItem onClick={() => onClickAction(action)} key={action.name}>
                        {action.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ActionsListButton;
