export const benefits: { [key: string]: string[] } = {
    start: [
        'Various apparel types',
        'Multiple angles and frames',
        'Diverse model generation',
        'Studio and lifestyle backgrounds',
        'Commercial use license',
        '300 fast generations per month',
        'Unlimited slow generations',
    ],
    pro: ['All Starter features', 'Upscale to 4K', 'Unlimited fast generations'],
    business: ['All Pro features', '10 parallel generations', 'Priority support'],
    enterprise: [
        'Unlimited generations',
        'Unlimited parallel generations',
        'Supports all product types',
        'Complex product handling',
        'API access',
    ],
};

export const descriptions: { [key: string]: string } = {
    business: 'Premium solution for large-scale e-commerce and enterprise needs',
    enterprise: 'The ultimate solution for large businesses requiring dedicated resources and tailored support.',
    pro: 'Ideal for growing brands needing more features and faster results',
    start: 'Perfect for individuals, startups, and small businesses',
};
