import analytics from '../../analytics';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BillingCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => navigate('/subscriptions'), 500);

        const parts = location.pathname.split('/');
        const lastPart = parts[parts.length - 1];
        if (lastPart === 'paid') analytics.mixpanel.track('subscriptions_paid');
    }, [navigate]);

    return null;
};

export default BillingCallback;
