import { useCallback, useMemo, useRef, useState } from 'react';
import api from '../../../api';
import type { Props } from './types';
import utils from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';

const useLogic = (props: Props) => {
    const { stackId } = useParams<{ stackId: any }>();
    const navigate = useNavigate();

    const stacksAPI = api.stack.useStacks();

    const [imageSize, setImageSize] = useState<{ height: number; width: number }>();
    const [containerSize, setContainerSize] = useState<{ height: number; width: number }>();

    const stack = useMemo(() => stacksAPI.list.find((stack) => stack.id == stackId)!, [stacksAPI.list, stackId]);
    const image = useMemo(() => stack && utils.stack.getStackImage(stack), [stack]);

    const handleClose = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const onClickPin = useCallback(async () => {
        await stacksAPI.pin(stack.id);
    }, [stack]);

    const onImageLoad = useCallback((event: any) => {
        const image = event.target;
        setImageSize({ height: image.naturalHeight, width: image.naturalWidth });
    }, []);

    const [isMaskEditorEnabled, setMaskEditorEnabled] = useState(false);
    const enableMaskEditor = useCallback((enabled: boolean = true) => {
        setMaskEditorEnabled(enabled);
    }, []);
    const maskEditorRef = useRef<{ setBrushSize?: (size: number) => void; getData?: () => Promise<Blob> }>({});

    return {
        image,
        imageSize,
        onClickPin,
        onImageLoad,
        stack,
        isMaskEditorEnabled,
        enableMaskEditor,
        maskEditorRef,
        handleClose,
        containerSize,
        setContainerSize,
    };
};

export default useLogic;
