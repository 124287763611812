import analytics from '../../analytics';
import apiAuth from '../../api/auth';
import { useEffect, useState } from 'react';
import { Props } from './types';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Loader } from '../../components';
import * as S from './style';
import { Typography } from '@mui/material';

const Auth = ({ children }: Props) => {
    const location = useLocation();
    const [tokenSet, setTokenSet] = useState(false);
    const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();

    useEffect(() => {
        analytics.google.pageView(location.pathname + location.search);
    }, [location]);

    useEffect(() => {
        if (user?.sub) analytics.mixpanel.setUser(user.sub);
    }, [user]);

    useEffect(() => {
        const checkAuth = async () => {
            if (isLoading) return;
            if (isAuthenticated) {
                const authToken = await getAccessTokenSilently();
                apiAuth.token = authToken;
                apiAuth.userId = user?.sub;
                setTokenSet(true);
                return;
            }

            loginWithRedirect();
        };
        checkAuth();
    }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, user]);

    if (isLoading)
        return (
            <S.LoadingContainer>
                <S.LoadingView>
                    <img src="/logo_black.svg" alt="logo" />
                    <Typography variant="body2">Loading</Typography>
                </S.LoadingView>
            </S.LoadingContainer>
        );
    if (!isAuthenticated) return null;
    if (!tokenSet) return null;

    return children;
};

export default Auth;
