import { useDropzone } from 'react-dropzone';
import { Props } from './types';
import { useMemo } from 'react';

const useLogic = (props: Props) => {
    const dropzoneProps = useMemo(
        () => ({
            accept: props.accept,
            onDrop: (files: File[]) => props.onChange(files[0]),
            multiple: false,
        }),
        [],
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneProps);
    return {
        getRootProps,
        getInputProps,
        isDragActive,
    };
};

export default useLogic;
