import useLogic from './logic';
import S from './style';
import type { Props } from './types';

const Actions = (props: Props) => {    
    const {} = useLogic(props);
    return (
        <S.Container>
            <S.Button onClick={props.onReset} variant="text" color="error">
                Reset
            </S.Button>
            <S.Button disabled={!props.canGenerate} onClick={props.onGenerate} variant="contained">
                {!props.loading ? 'Create' : 'Creating'}
            </S.Button>
        </S.Container>
    );
};

export default Actions;
