import { useRef, useEffect } from 'react';
import S from './style';
import { Props } from './types';

const MaskEditor = (props: Props) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const cursorRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d')!;

        // Function to draw a circle where the user clicks

        let brushSize = 75;

        props.maskEditorRef.current!.setBrushSize = (size: number) => {
            brushSize = size;
        };
        props.maskEditorRef.current!.getData = () => {
            var data = context.getImageData(0, 0, props.imageSize.width, props.imageSize.height);

            // turn into black and white
            var i = 0;
            while (i < data.data.length) {
                const r = data.data[i];
                const g = data.data[i + 1];
                const b = data.data[i + 2];
                // const alpha = data.data[i + 3];

                if (r + b + g === 0) {
                    data.data[i] = 0;
                    data.data[i + 1] = 0;
                    data.data[i + 2] = 0;
                    data.data[i + 3] = 255;
                } else {
                    data.data[i] = 255;
                    data.data[i + 1] = 255;
                    data.data[i + 2] = 255;
                    data.data[i + 3] = 255;
                }

                i += 4;
            }
            context.putImageData(data, 0, 0);

            return new Promise<Blob>((res) => {
                canvas.toBlob((blob) => {
                    blob?.arrayBuffer().then((r) => console.log(r.byteLength));

                    res(blob!);
                });
            });
        };

        let isDrawing: boolean = false;

        const getCanvasCoordinates = (event: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();

            const cX = props.imageSize.width / rect.width;
            const cY = props.imageSize.height / rect.height;

            const dX = event.clientX - rect.left;
            const dY = event.clientY - rect.top;

            return { coords: [dX * cX, dY * cY], cX, cY, dX, dY } as const;
        };

        const onMouseDown = (e: MouseEvent) => {
            const { coords, cX } = getCanvasCoordinates(e);

            isDrawing = true;
            context.beginPath();
            context.lineWidth = brushSize * cX;
            context.strokeStyle = 'red';
            context.lineJoin = 'round';
            context.lineCap = 'round';
            context.moveTo(...coords);
            context.lineTo(...coords);
            context.stroke();
        };

        const onMouseMove = (e: MouseEvent) => {
            const { coords, dX, dY } = getCanvasCoordinates(e);

            if (isDrawing) {
                context.lineTo(...coords);
                context.stroke();
            }
            cursorRef.current!.style.left = `${dX}px`;
            cursorRef.current!.style.top = `${dY}px`;
        };

        const onMouseUp = function () {
            isDrawing = false;
            context.closePath();
        };

        const onMouseLeave = function () {
            cursorRef.current!.style.display = 'none';
        };

        const onMouseEnter = function () {
            cursorRef.current!.style.display = 'block';
            cursorRef.current!.style.width = `${brushSize}px`;
            cursorRef.current!.style.height = `${brushSize}px`;
        };

        // Add the event listener
        canvas.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mousemove', onMouseMove);
        canvas.addEventListener('mouseup', onMouseUp);
        canvas.addEventListener('mouseleave', onMouseLeave);
        canvas.addEventListener('mouseenter', onMouseEnter);

        // Clean up function
        return () => {
            canvas.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mousemove', onMouseMove);
            canvas.removeEventListener('mouseup', onMouseUp);
            canvas.removeEventListener('mouseleave', onMouseLeave);
            canvas.removeEventListener('mouseenter', onMouseEnter);

            props.maskEditorRef.current!.setBrushSize = undefined;
            props.maskEditorRef.current!.getData = undefined;
        };
    }, []);

    return (
        <>
            <S.Canvas ref={canvasRef} width={props.imageSize.width} height={props.imageSize.height} />
            <S.Cursor ref={cursorRef} />
        </>
    );
};

export default MaskEditor;
