import { useCallback, useMemo } from 'react';
import type { Props } from './types';
import { aspectRatios, versions } from '../../../../components/Generate/options';
import utils from '../../../../utils';

const useLogic = (props: Props) => {
    const generateOperation = useMemo(() => props.stack.operations[props.stack.operations.length - 1], [props.stack]);
    const upscaleOperation = useMemo(() => props.stack.operations[props.stack.operations.length - 2], [props.stack]);

    const aspectRatio = useMemo(() => {
        const aspectRatio = generateOperation.inputData.aspectRatio;
        return aspectRatios.find((ar) => ar.value === aspectRatio)?.name ?? '1:1';
    }, [generateOperation]);
    const resolution = useMemo(
        () => `${props.stackImageSize.width}x${props.stackImageSize.height}`,
        [props.stackImageSize],
    );
    const seed = useMemo(() => generateOperation.inputData.seed, [generateOperation]);
    const version = useMemo(() => {
        const version = generateOperation.outputData.resultImageVersions?.[upscaleOperation.inputData.index];
        return versions.find((v) => v.value === version)?.name ?? '';
    }, [generateOperation, upscaleOperation]);

    const onClickDownload = useCallback(async () => {
        const stackImage = utils.stack.getStackImage(props.stack);
        if (!stackImage?.url) return;

        await utils.download.image(stackImage.url);
    }, [props.stack]);

    const parametersState = useMemo(() => {
        const parameters = generateOperation.inputData.parameters ?? {};

        return { ...parameters, fileUrl: generateOperation.inputData.productImageUrl };
    }, [generateOperation]);
    const prompt = useMemo(() => generateOperation.inputData.prompt, [generateOperation]);

    return {
        aspectRatio,
        onClickDownload,
        parametersState,
        prompt,
        resolution,
        seed,
        version,
    };
};

export default useLogic;
