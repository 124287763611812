import { styled } from '@mui/material/styles';

const style = {
    BlockingActions: styled('div')<{ blocked: boolean }>`
        align-items: center;
        background-color: ${({ blocked }) => (blocked ? '#f5f5f5' : 'white')};
        display: flex;
        flex-direction: row;
        gap: 16px;
        pointer-events: ${({ blocked }) => (blocked ? 'none' : 'auto')};
    `,
    Buttons: styled('div')`
        align-items: center;
        background-color: #efefef;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        padding: 16px;
        width: 384px;
    `,
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
    `,
};

export default style;
