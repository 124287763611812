import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import S from './style';
import { Typography } from '@mui/material';
import Example from './Example';

const codeExample = `
import createHautechSDK from '@hautech/web-sdk';

const sdk = createHautechSDK({ token: 'YOUR_TOKEN' });
const widget = sdk.widgets.basic({
  input: {
    image: { url: 'YOUR_APPAREL_IMAGE_URL' }, // Required
    prompt: 'YOUR_PROMPT' // Optional,
    sessionId: 'SESSION_ID' // Required (unique identifier for the set of generations)
 },
  output: {
    image: (url: string) => {
      // Here you can do something with an output image
    },
  },
});

// Recommended size of widget container is 400x600 pixels
const container = document.getElementById('iframeContainer');
container.appendChild(widget.iframe);

// If you want to update input image
widget.update({ input: { image: { url: 'ANOTHER_APPAREL_IMAGE_URL' }, prompt: 'ANOTHER_PROMPT', sessionId: 'ANOTHER_SESSION_ID' } });
`;

const Developers = () => (
    <S.Container>
        <S.Content>
            <S.CodeExample>
                <Typography variant="h6">Widget integration</Typography>
                <S.Text>
                    <Typography variant="body1">
                        You can integrate our widget into your website using our Web SDK.
                    </Typography>
                    <Typography variant="body1">The code example below shows how to do it.</Typography>
                    <Typography variant="body1">
                        To obtain a token please contact us via email at <b>support@hautech.ai</b>
                    </Typography>
                </S.Text>
                <SyntaxHighlighter language="javascript" style={materialLight}>
                    {codeExample}
                </SyntaxHighlighter>
                <S.Text>
                    <Typography variant="body1">You can check how it works in the example below</Typography>
                </S.Text>
            </S.CodeExample>
            <S.Divider />
            <Example />
        </S.Content>
    </S.Container>
);

export default Developers;
