
import { MaterialSymbol } from 'react-material-symbols';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';

const Details = (props: Props) => {
    const { aspectRatio, onClickDownload, parametersState, prompt, resolution, seed, version } = useLogic(props);

    const renderField = (label: string, value: string) => (
        <S.Field>
            <S.FieldLabel>{label}</S.FieldLabel>
            <S.FieldValue>{value}</S.FieldValue>
        </S.Field>
    );

    return (
        <S.Container>
            {parametersState && (
                <S.Section>
                    <S.BarChipsContainer>
                        {/* <BarChips state={parametersState} /> */}
                    </S.BarChipsContainer>
                </S.Section>
            )}
            {prompt && (
                <S.Section>
                    <S.PromptContainer>
                        <S.FieldLabel>Prompt</S.FieldLabel>
                        <S.Prompt>{prompt}</S.Prompt>
                    </S.PromptContainer>
                </S.Section>
            )}
            <S.Section>
                <S.Fields>
                    {renderField('Aspect ratio', aspectRatio)}
                    {renderField('Resolution', resolution)}
                    {renderField('Seed', seed)}
                    {renderField('Version', version)}
                </S.Fields>
            </S.Section>
            <S.Section>
                <S.Buttons>
                    <S.Button onClick={onClickDownload} variant="outlined">
                        <MaterialSymbol icon="download" size={24} />
                        Download
                    </S.Button>
                </S.Buttons>
            </S.Section>
        </S.Container>
    );
};

export default Details;
