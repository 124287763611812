import { MenuItem } from '@mui/material';
import { useMemo } from 'react';
import useLogic from './logic';
import * as S from './style';
import type { Props } from './types';

const Select = <T extends string>(props: Props<T>) => {
    const { onChange } = useLogic(props);


    return (
        <S.Container>
            <S.SelectFormControl variant="standard">
                {props.title && <S.SelectInputLabel>{props.title}</S.SelectInputLabel>}
                <S.Select
                    variant={props.variant}
                    onChange={onChange}
                    placeholder={props.title}
                    value={props.value ?? null}
                >
                    {props.options.map((option, index) => (
                        <MenuItem key={index} value={option.value as string}>
                            {option.name}
                        </MenuItem>
                    ))}
                </S.Select>
            </S.SelectFormControl>
        </S.Container>
    );
};

export default Select;
