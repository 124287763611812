import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { MaterialSymbol } from 'react-material-symbols';
import ActionsListButton from './ActionsListButton';
import MaskEditorActions from './MaskEditorActions';

const Actions = (props: Props) => {
    const {
        onClickUndo,
        onClickUpscale,
        processing,
        undoProcessing,
        replaceActions,
        retouchActions,
        upscaleEnabled,
        replaceAreaHandler,
    } = useLogic(props);

    return (
        <S.Container>
            <S.Buttons>
                <Tooltip title="Undo operation">
                    <IconButton
                        disabled={
                            props.stack.operations.length === 1 ||
                            (props.stack.operations.length === 2 &&
                                props.stack.operations[0].operationId === 'generate')
                        }
                        onClick={onClickUndo}
                    >
                        {undoProcessing ? <CircularProgress size={24} /> : <MaterialSymbol icon="undo" size={24} />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Redo operation">
                    <IconButton disabled>
                        <MaterialSymbol icon="redo" size={24} />
                    </IconButton>
                </Tooltip>
            </S.Buttons>

            <S.BlockingActions blocked={processing}>
                {!props.isMaskEditorEnabled && (
                    <S.Buttons>
                        <ActionsListButton actions={replaceActions} icon="apparel" name="Replace" />
                        <ActionsListButton actions={retouchActions} icon="face" name="Retouch" />
                        <Tooltip title="Upscale">
                            <Button disabled={!upscaleEnabled} onClick={onClickUpscale} variant="outlined">
                                Upscale
                            </Button>
                        </Tooltip>
                    </S.Buttons>
                )}
                {props.isMaskEditorEnabled && (
                    <MaskEditorActions
                        enableMaskEditor={props.enableMaskEditor}
                        maskEditorRef={props.maskEditorRef}
                        replaceAreaHandler={replaceAreaHandler}
                    />
                )}
            </S.BlockingActions>
        </S.Container>
    );
};

export default Actions;
