import { useCallback, useMemo, useState } from 'react';
import type { Props } from './types';
import utils from '../../../../utils';
import api from '../../../../api';
import { OperationDto, OperationRunResponse } from '../../../../api/types';

const useLogic = (props: Props) => {
    const operationsAPI = api.operation.useOperations();
    const stacksAPI = api.stack.useStacks();

    const [loading, setLoading] = useState(false);

    const operation = useCallback(
        (
            runner: (data: {
                imageUrl: string;
                previousOperationExternalId: string;
                previousOperationId: number;
            }) => Promise<OperationRunResponse>,
        ) => {
            return async () => {
                const stackImage = utils.stack.getStackImage(props.stack);
                if (!stackImage?.url) return;

                const lastOperation = props.stack.operations.filter(
                    (operation: OperationDto) => operation.operationId !== 'upscale',
                )[0];

                setLoading(true);
                try {
                    const response = await runner({
                        imageUrl: stackImage.url,
                        previousOperationExternalId: lastOperation.externalId,
                        previousOperationId: lastOperation.id,
                    });
                    if (!response.data) return;

                    await stacksAPI.update({
                        id: props.stack.id,
                        data: { operationId: response.data?.id },
                    });
                } finally {
                    setLoading(false);
                }
            };
        },
        [props.stack],
    );

    const replaceAreaHandler = async (params: { prompt: string; maskUrl: string; strength: number }) => {
        const stackImage = utils.stack.getStackImage(props.stack);
        if (!stackImage?.url) return;

        const lastOperation = props.stack.operations.filter(
            (operation: OperationDto) => operation.operationId !== 'upscale',
        )[0];

        setLoading(true);
        try {
            const response = await operationsAPI.replaceArea({
                imageUrl: stackImage.url,
                previousOperationExternalId: lastOperation.externalId,
                previousOperationId: lastOperation.id,
                prompt: params.prompt,
                maskUrl: params.maskUrl,
                strength: params.strength,
            });
            if (!response.data) return;

            await stacksAPI.update({
                id: props.stack.id,
                data: { operationId: response.data?.id },
            });
        } finally {
            setLoading(false);
        }
    };

    const replaceActions = useMemo(
        () => [
            { name: 'Replace face', onClick: operation((data) => operationsAPI.replaceFace(data)) },
            { name: 'Replace area', onClick: () => props.enableMaskEditor() },
        ],
        [operation, props.enableMaskEditor],
    );
    const retouchActions = useMemo(
        () => [
            { name: 'Retouch bottom', onClick: operation((data) => operationsAPI.retouchBottom(data)) },
            { name: 'Retouch dress', onClick: operation((data) => operationsAPI.retouchDress(data)) },
            { name: 'Retouch face', onClick: operation((data) => operationsAPI.retouchFace(data)) },
            { name: 'Retouch top', onClick: operation((data) => operationsAPI.retouchTop(data)) },
        ],
        [operation],
    );

    const onClickUndo = useCallback(async () => {
        setLoading(true);

        try {
            await stacksAPI.revert(props.stack.id);
        } finally {
            setLoading(false);
        }
    }, [props.stack.id]);

    const onClickUpscale = useCallback(
        operation((data) => operationsAPI.upscale4k({ ...data, version: 1 })),
        [operation],
    );

    const processing = useMemo(() => {
        const lastOperation = props.stack.operations.filter(
            (operation: OperationDto) => operation.operationId !== 'upscale',
        )[0];
        return (
            loading || !['completed', 'failed'].includes(lastOperation.status) || stacksAPI.revert.mutation.isPending
        );
    }, [loading, props.stack.operations, stacksAPI.revert.mutation.isPending]);

    const upscaleEnabled = useMemo(
        () => props.stack.operations.every((operation: OperationDto) => operation.operationId !== 'upscale4k'),
        [props.stack],
    );

    return {
        onClickUndo,
        onClickUpscale,
        processing,
        replaceActions,
        retouchActions,
        undoProcessing: stacksAPI.revert.mutation.isPending,
        upscaleEnabled,
        replaceAreaHandler,
    };
};

export default useLogic;
