import type { Props } from './types';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useLogic = (props: Props) => {
    const { logout, user } = useAuth0();
    const navigate = useNavigate();

    const onClickLogout = useCallback(() => logout(), [logout]);
    return { onClickLogout, navigate, user };
};

export default useLogic;
