import { styled } from '@mui/material/styles';

const style = {
    Canvas: styled('canvas')`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 1);
        opacity: 0.5;
    `,
    Cursor: styled('div')`
        display: none;
        position: absolute;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 1px solid red;
        transform: translate(-50%, -50%);
        pointer-events: none;
    `,
};

export default style;
