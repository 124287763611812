import analytics from '../../analytics';
import api from '../../api';
import type { Props } from './types';
import { useCallback, useEffect } from 'react';

const useLogic = (props: Props) => {
    const discordBotAPI = api.bots.useDiscordConnection();

    const onClickAdd = useCallback(async () => {
        analytics.mixpanel.track('bots_discord_click_add');
        analytics.google.track('bots', 'discord_click_add');

        window.open(process.env.REACT_APP_DISCORD_BOT_INVITE_URL, '_blank');
    }, []);
    const onClickDisconnect = useCallback(async () => {
        analytics.mixpanel.track('bots_discord_click_disconnect');
        analytics.google.track('bots', 'discord_click_disconnect');

        await discordBotAPI.disconnect();
    }, [discordBotAPI.disconnect]);

    useEffect(() => {
        const autoConnect = async () => {
            const discordId = localStorage.getItem('connect-discordId');
            if (!discordId) return;
            if (discordBotAPI.dataLoading) return;

            localStorage.removeItem('connect-discordId');
            if (discordBotAPI.data) return;

            analytics.mixpanel.track('bots_discord_autoconnect', { discordId });
            analytics.google.track('bots', 'discord_autoconnect');

            await discordBotAPI.connect(discordId);
        };
        autoConnect();
    }, [discordBotAPI.connect, discordBotAPI.data, discordBotAPI.dataLoading]);

    return {
        disconnectLoading: discordBotAPI.disconnect.mutation.isPending,
        discordBotConnected: !!discordBotAPI.data,
        loading: discordBotAPI.dataLoading,
        onClickAdd,
        onClickDisconnect,
    };
};

export default useLogic;
