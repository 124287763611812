import { ApparelChip, BackgroundChip, BaseChip, ModelChip, PromptChip } from '../../Chips';
import { FormTab } from '../../types';
import useLogic from './logic';
import * as S from './style';
import type { Props } from './types';

const tabs: { key: FormTab; chip: React.FC<{ state: {} }> }[] = [
    { key: 'apparel', chip: ApparelChip }, //
    { key: 'model', chip: ModelChip }, //
    { key: 'background', chip: BackgroundChip }, //
    { key: 'prompt', chip: PromptChip }, //
    // 'model',
    // 'background',
    // // 'photo',
    // 'prompt',
    // 'advanced',
];

const TabSwitcher = (props: Props) => {
    const {} = useLogic(props);
    return (
        <S.Container>
            {tabs.map((tab) => (
                <S.Button selected={props.tab === tab.key} onClick={() => props.onChange(tab.key)} key={tab.key}>
                    <tab.chip state={props.state} />
                </S.Button>
            ))}
            <S.Spacer />
            <S.Button selected={props.tab === 'advanced'} onClick={() => props.onChange('advanced')}>
                <BaseChip label="Advanced" icon='/icons/advanced.svg' />
            </S.Button>
        </S.Container>
    );
};

export default TabSwitcher;
