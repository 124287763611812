import 'yet-another-react-lightbox/styles.css';

import Lightbox from 'yet-another-react-lightbox';
import useLogic from './logic';
import type { Props } from './types';

const carousel = { finite: true };
const render = { buttonPrev: () => null, buttonNext: () => null };

const LightboxImage = (props: Props) => {
    const { lightboxOpen, onClickImage, onCloseLightbox, slides } = useLogic(props);
    return (
        <>
            <img onClick={onClickImage} {...props} />
            <Lightbox carousel={carousel} close={onCloseLightbox} open={lightboxOpen} render={render} slides={slides} />
        </>
    );
};

export default LightboxImage;
