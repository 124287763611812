import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { Loader } from '../../components';
import { CircularProgress } from '@mui/material';

const Comparison = (props: Props) => {
    const { loading, onClickFirstOutput, onClickSecondOutput, pair, selecting } = useLogic(props);

    if (loading) return <Loader />;
    if (!pair)
        return (
            <S.EmptyState>
                <S.EmptyStateTitle>No pair found</S.EmptyStateTitle>
            </S.EmptyState>
        );

    const renderOutput = (imageUrl: string, onClick: () => void) => (
        <S.OutputContainer>
            <S.OutputImage src={imageUrl} />
            <S.OutputButton disabled={selecting} onClick={onClick} variant="contained">
                {selecting ? <CircularProgress size={24} /> : 'SELECT'}
            </S.OutputButton>
        </S.OutputContainer>
    );

    return (
        <S.Container>
            <S.InputContainer>
                <S.InputImage src={pair.input.productImageUrl} />
                <S.InputPrompt>
                    <b>Prompt: </b>
                    {pair.input.prompt}
                </S.InputPrompt>
            </S.InputContainer>
            <S.Outputs>
                {renderOutput(pair.firstOutput.imageUrl, onClickFirstOutput)}
                {renderOutput(pair.secondOutput.imageUrl, onClickSecondOutput)}
            </S.Outputs>
        </S.Container>
    );
};

export default Comparison;
