import { Typography } from '@mui/material';
import { startCase } from 'lodash';
import { SubscriptionPlan } from '../../api/types';
import { Loader } from '../../components';
import useLogic from './logic';
import S from './style';
import { benefits, descriptions } from './texts';
import type { Props } from './types';

const Subscriptions = (props: Props) => {
    const { buttonsLoading, getSubscribeButtonText, loading, onClickCancel, onClickSubscribe, plans, subscription } =
        useLogic(props);
    if (loading) return <Loader />;

    const renderBenefit = (selected: boolean) => (benefit: string) =>
        (
            <S.Benefit key={benefit}>
                <img src={selected ? '/icons/benefit-white.svg' : '/icons/benefit-primary.svg'} />
                <S.BenefitLabel>{benefit}</S.BenefitLabel>
            </S.Benefit>
        );

    const renderPrice = (price: number, period: 'month' | 'year') => (
        <S.PriceContainer>
            <S.Price>
                <S.PriceValue>${price}</S.PriceValue>
                <S.PricePeriod>/{period}</S.PricePeriod>
            </S.Price>
        </S.PriceContainer>
    );

    const renderPlan = (plan: SubscriptionPlan) => {
        const selected = plan.id === (subscription?.subscription ?? 'pro');
        const activeSubscription = subscription?.status === 'active' || subscription?.status === 'trialing';
        const canceledSubscription = subscription?.status === 'canceled';
        return (
            <S.Card selected={selected} key={plan.id}>
                <S.CardContent>
                    <S.CardMainContent>
                        <S.PlanName>{startCase(plan.id)} plan</S.PlanName>
                        {renderPrice(plan.amount, 'month')}
                        <S.PlanDescription>{descriptions[plan.id]}</S.PlanDescription>
                        <S.Benefits>{benefits[plan.id].map(renderBenefit(selected))}</S.Benefits>
                    </S.CardMainContent>
                    <S.BottomContainer>
                        <S.RenewDate>
                            {selected && activeSubscription && (
                                <>
                                    Renew on{' '}
                                    {subscription?.periodEnd &&
                                        new Date(subscription?.periodEnd * 1000).toLocaleDateString()}
                                </>
                            )}
                            {selected && canceledSubscription && (
                                <>
                                    Active until{' '}
                                    {subscription?.periodEnd &&
                                        new Date(subscription?.periodEnd * 1000).toLocaleDateString()}
                                </>
                            )}
                        </S.RenewDate>

                        <S.Button
                            selected={selected && !!subscription?.subscription}
                            color="primary"
                            disabled={buttonsLoading}
                            onClick={() => onClickSubscribe(plan.id)}
                            variant="outlined"
                        >
                            {!!subscription ? getSubscribeButtonText(plan.id) : 'Subscribe'}
                        </S.Button>
                    </S.BottomContainer>
                </S.CardContent>
            </S.Card>
        );
    };

    const renderEnterprisePlan = () => {
        const selected = subscription?.subscription === 'enterprise';
        return (
            <S.Card selected={selected}>
                <S.CardContent>
                    <S.CardMainContent>
                        <S.PlanName>Enterprise plan</S.PlanName>
                        <S.PlanDescription>{descriptions['enterprise']}</S.PlanDescription>
                        <S.Benefits>{benefits.enterprise.map(renderBenefit(false))}</S.Benefits>
                    </S.CardMainContent>
                    {!selected && (
                        <S.Button
                            disabled={buttonsLoading}
                            href="mailto:support@hautech.ai?subject=Enterprise%20plan"
                            variant="outlined"
                        >
                            Contact us
                        </S.Button>
                    )}
                </S.CardContent>
            </S.Card>
        );
    };

    return (
        <S.Container>
            <Typography variant="h4" component="div" textAlign="center">
                Find the Perfect Plan for Your Business
            </Typography>
            <Typography variant="body1" component="div" textAlign="center">
                Choose from flexible subscription options designed to fit every stage of your growth.
            </Typography>
            <S.PricingContainer>
                {plans.map((plan) => renderPlan(plan))}
                {renderEnterprisePlan()}
            </S.PricingContainer>
        </S.Container>
    );
};

export default Subscriptions;
