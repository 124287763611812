import mixpanel, { track } from 'mixpanel-browser';
import ReactGA from 'react-ga4';

let googleEnabled = false;
let mixpanelEnabled = false;

const init = () => {
    const googleToken = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
    const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

    if (googleToken) {
        googleEnabled = true;
        ReactGA.initialize(googleToken);
    }

    if (mixpanelToken) {
        mixpanelEnabled = true;
        mixpanel.init(mixpanelToken);
    }
};
init();

const call = (enabled: boolean, func: () => void) => {
    if (enabled) func();
};

const PREFIX = 'web_app';

const analytics = {
    google: {
        pageView: (path: string) => call(googleEnabled, () => ReactGA.send({ hitType: 'pageview', page: path })),
        track: (category: string, action: string, label?: string) =>
            call(googleEnabled, () => ReactGA.event({ action, category, label })),
    },
    mixpanel: {
        setUser: (userId: string) => call(mixpanelEnabled, () => mixpanel.identify(userId)),
        track: (event: string, data?: any) => call(mixpanelEnabled, () => mixpanel.track(`${PREFIX}_${event}`, data)),
    },
};

export default analytics;
