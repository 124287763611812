import { Typography } from '@mui/material';
import { Props } from './types';
import * as S from './style';
import useLogic from './logic';

const FileDropzone = (props: Props) => {
    const { getRootProps, getInputProps, isDragActive } = useLogic(props);

    return (
        <S.Paper {...getRootProps()} isDragActive={isDragActive}>
            <input {...getInputProps()} />
            {props.value ? (
                props.showPreview ? (
                    <S.Preview url={typeof props.value === 'string' ? props.value : URL.createObjectURL(props.value)} />
                ) : (
                    <Typography variant="h6">
                        Chosen {typeof props.value === 'string' ? 'file from outside' : props.value.name}
                    </Typography>
                )
            ) : (
                <>{props.placeholder}</>
            )}
        </S.Paper>
    );
};

export default FileDropzone;
