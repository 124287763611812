import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    CodeExample: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        padding-bottom: 16px;
        padding-top: 16px;
    `,
    Container: styled('div')`
        flex: 1;
        overflow-y: scroll;
    `,
    Content: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    `,
    Divider: styled(Divider)`
        height: 1px;
        width: 100%;
    `,
    Text: styled('div')`
        display: flex;
        flex-direction: column;
    `,
};

export default style;
