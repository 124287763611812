import { Typography } from '@mui/material';
import { FileDropzone, Select, SelectImage } from '../../../../components';
import { ages, angles, aspectRatios, backgrounds, genders, models, photoTypes, versions } from '../../options';
import useLogic from './logic';
import * as S from './style';
import type { Props } from './types';
import { ApparelChip, BackgroundChip, ModelChip } from '../../Chips';
import { FormMode } from '../../types';

const ApparelPlaceholder = () => {
    return (
        <>
            <S.PlaceholderIconsContainer>
                <img src="/icons/swimsuit.svg" alt="Swimsuit" />
                <img src="/icons/dress.svg" alt="Dress" />
                <img src="/icons/shirt.svg" alt="Shirt" />
            </S.PlaceholderIconsContainer>
            <Typography variant="body1">
                Drag an image of your apparel here or{' '}
                <S.PlaceholderLink variant="body1">upload a file</S.PlaceholderLink>
            </Typography>
        </>
    );
};

const PhotoPlaceholder = () => {
    return (
        <>
            <S.PlaceholderIconsContainer>
                <img src="/icons/camera.svg" alt="Camera" />
            </S.PlaceholderIconsContainer>
            <Typography variant="body1">
            Drag an image here or{' '}
                <S.PlaceholderLink variant="body1">upload a file</S.PlaceholderLink>
            </Typography>
        </>
    );
};


const PromptPreview = (props: { state: Props['state'] }) => {
    return (
        <S.PromptPreviewContainer>
            {props.state.file && <ApparelChip state={props.state} />}
            {props.state.model && (
                <>
                    on a <ModelChip state={props.state} />
                </>
            )}
            {props.state.background && (
                <>
                    with <BackgroundChip state={props.state} />
                </>
            )}
        </S.PromptPreviewContainer>
    );
};

const TabContent = (props: Props) => {
    const {
        onChangeAge,
        onChangeAngle,
        onChangeAspectRatio,
        onChangeBackground,
        onChangeModel,
        onChangeFile,
        onChangeGender,
        onChangePhotoType,
        onChangePrompt,
        onChangeSeed,
        onChangeVersion,
    } = useLogic(props);

    const renderTabContent = () => {
        switch (props.tab) {
            case 'advanced':
                return (
                    <S.Container>
                        <S.VerticalContainer>
                            <S.HorizontalContainer>
                                <Typography variant="body2" fontWeight={'bold'}>
                                    Aspect ratio:
                                </Typography>
                                <Select
                                    onChange={onChangeAspectRatio}
                                    options={aspectRatios}
                                    title="Aspect ratio"
                                    value={props.state.aspectRatio}
                                    variant="outlined"
                                />
                            </S.HorizontalContainer>
                            <S.HorizontalContainer>
                                <Typography variant="body2" fontWeight={'bold'}>
                                    AI model version:
                                </Typography>
                                <Select
                                    onChange={onChangeVersion}
                                    options={versions}
                                    title="AI model version"
                                    value={props.state.version}
                                    variant="outlined"
                                />
                            </S.HorizontalContainer>
                            <S.HorizontalContainer>
                                <Typography variant="body2" fontWeight={'bold'}>
                                    Seed:
                                </Typography>
                                <S.SeedInput
                                    onChange={onChangeSeed}
                                    placeholder="Seed"
                                    type="number"
                                    variant="outlined"
                                />
                            </S.HorizontalContainer>
                        </S.VerticalContainer>
                    </S.Container>
                );
            case 'apparel':
                return (
                    <S.InitContainer>
                            <S.DropzoneContainer>
                                <S.FileDropzoneContainer>
                                    <FileDropzone
                                        onChange={onChangeFile}
                                        showPreview
                                        placeholder={<ApparelPlaceholder />}
                                        value={props.state.file}
                                    />
                                </S.FileDropzoneContainer>
                            </S.DropzoneContainer>
                    </S.InitContainer>
                );
            case 'photo':
                return (
                    <S.InitContainer>
                        <S.InitContainerLeftSide>
                            <S.DropzoneContainer>
                                <S.FileDropzoneContainer>
                                    <FileDropzone
                                        onChange={onChangeFile}
                                        showPreview
                                        placeholder={<PhotoPlaceholder />}
                                        value={props.state.file}
                                    />
                                </S.FileDropzoneContainer>
                            </S.DropzoneContainer>
                        </S.InitContainerLeftSide>
                    </S.InitContainer>
                );
            case 'background':
                return (
                    <S.Container>
                        <SelectImage
                            optional
                            onChange={onChangeBackground}
                            options={backgrounds.map(({ value }) => ({
                                id: value,
                                url: `/backgrounds/${value}.jpg`,
                            }))}
                            value={props.state.background}
                            size="large"
                        />
                    </S.Container>
                );
            case 'model':
                return (
                    <S.Container>
                        <S.VerticalContainer>
                            <S.HorizontalContainer>
                                <Select
                                    onChange={onChangeGender}
                                    options={genders}
                                    value={props.state.gender}
                                    variant="outlined"
                                />
                                <Select
                                    onChange={onChangeAge} //
                                    options={ages}
                                    value={props.state.age}
                                    variant="outlined"
                                />
                            </S.HorizontalContainer>
                            <S.HorizontalContainer>
                                {props.state.age && props.state.gender && (
                                    <SelectImage
                                        optional
                                        onChange={onChangeModel}
                                        options={models.map(({ value }) => ({
                                            id: value,
                                            url: `/models/${props.state.gender}_${props.state.age}_${value}.jpg`,
                                        }))}
                                        value={props.state.model}
                                        size="small"
                                    />
                                )}
                            </S.HorizontalContainer>
                        </S.VerticalContainer>
                    </S.Container>
                );
            case 'photo':
                return (
                    <S.Container>
                        <S.VerticalContainer>
                            <S.HorizontalContainer>
                                <Select
                                    onChange={onChangeAngle}
                                    options={angles}
                                    title="Angle"
                                    value={props.state.angle}
                                />
                                <Select
                                    onChange={onChangePhotoType}
                                    options={photoTypes}
                                    title="Photo type"
                                    value={props.state.photoType}
                                />
                            </S.HorizontalContainer>
                        </S.VerticalContainer>
                    </S.Container>
                );
            case 'prompt':
                return (
                    <S.Container size={props.mode === 'text-to-image' ? 'text-to-image' : 'default'}>
                        <S.VerticalContainer>
                            {/* <Typography variant="body2" fontWeight={600} color="layout.onSurface.secondary">
                                Current prompt
                            </Typography>
                            <PromptPreview state={props.state} /> */}
                            <S.PromptInput
                                multiline
                                value={props.state.prompt}
                                onChange={onChangePrompt}
                                placeholder={
                                    props.mode === 'apparel'
                                        ? 'Provide any extra details or preferences for the generated on-model image, such as specific colors, fabric texture, pose style, or background setting'
                                        : 'Describe the complete scene for the generated image, including apparel style, model appearance, pose, background environment, and any other desired elements'
                                }
                                variant="outlined"
                                rows={5}
                            />
                        </S.VerticalContainer>
                    </S.Container>
                );
        }
    };

    return renderTabContent();
};

export default TabContent;
