import { Button, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Benefit: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
    `,
    Benefits: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 12px;
        width: 100%;
    `,
    Button: styled(Button)<{ selected?: boolean }>`
        border-radius: 100px;
        width: 100%;
        background-color: ${({ theme, selected }) => (selected ? 'transparent' : theme.palette.layout.surface.low)};

        &:hover {
            background-color: ${({ theme, selected }) => (selected ? 'transparent' : theme.palette.layout.surface.low)};
            border-color: ${({ selected }) => (selected ? 'white' : 'auto')};
        }

        border-color: ${({ selected }) => (selected ? 'white' : 'auto')};
        color: ${({ selected }) => (selected ? 'white' : 'auto')};
    `,

    Card: styled(Card)<{ selected: boolean }>`
        background-color: ${({ selected }) => (selected ? '#62929E' : 'white')};
        border-radius: 12px;
        color: ${({ selected }) => (selected ? 'white' : 'black')};
        height: 540px;
        width: 240px;
    `,
    CardContent: styled(CardContent)`
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    `,
    CardMainContent: styled('div')`
        align-items: start;
        display: flex;
        flex-direction: column;
        gap: 12px;
    `,
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 24px;
    `,
    PricingContainer: styled('div')`
        display: flex;
        gap: 24px;
        flex-direction: row;

        align-items: center;
        padding-bottom: 24px;
        padding-top: 12px;
    `,
    InnerContainer: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
    `,
    Price: styled('div')`
        align-items: end;
        display: flex;
        flex-direction: row;
        gap: 8px;
    `,
    PriceContainer: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
    `,
    Row: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
    `,
    PlanName: styled('div')`
        font-weight: bold;
        font-size: 16px;
    `,
    PriceValue: styled('span')`
        font-size: 32px;
        font-weight: bold;
        line-height: 30px;
    `,
    PricePeriod: styled('span')`
        font-size: 12px;
    `,
    PlanDescription: styled('span')`
        font-size: 14px;
    `,
    BenefitLabel: styled('span')`
        font-size: 14px;
    `,
    RenewDate: styled('span')`
        font-size: 12px;
    `,
    BottomContainer: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
    `,
};

export default style;
