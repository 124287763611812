import { useRef } from 'react';
import { Props } from './types';

const useLogic = (props: Props) => {
    const formRef = useRef<HTMLDivElement>(null);

    return {
        formRef,
    };
};

export default useLogic;
