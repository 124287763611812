import { capitalize } from 'lodash';
import * as S from './style';
import type { Props } from './types';

export const BaseChip = (props: { label: string; icon?: string }) => {
    return (
        <S.Chip>
            <S.Icon iconUrl={props.icon}>{!props.icon && <S.Empty />}</S.Icon>
            {capitalize(props.label)}
        </S.Chip>
    );
};

export const ApparelChip = (props: { state: { file?: File } }) => {
    return (
        <BaseChip //
            icon={props.state.file ? URL.createObjectURL(props.state.file as File) : undefined}
            label="Apparel"
        />
    );
};

export const ModelChip = (props: { state: { gender?: string; age?: string; model?: string } }) => {
    const { gender, age, model } = props.state;
    const modelUrl = gender && age && model ? `/models/${gender}_${age}_${model}.jpg` : undefined;
    return (
        <BaseChip //
            icon={modelUrl}
            label="Model"
        />
    );
};

export const BackgroundChip = (props: { state: { background?: string } }) => {
    const { background } = props.state;
    const backgroundUrl = background ? `/backgrounds/${background}.jpg` : undefined;
    return (
        <BaseChip //
            icon={backgroundUrl}
            label="Background"
        />
    );
};

export const PromptChip = (props: { state: { prompt?: string } }) => {
    return (
        <BaseChip //
            label="Prompt"
            icon={props.state.prompt ? '/icons/prompt_active.svg' :'/icons/prompt_inactive.svg'}
        />
    );
};

// const chipTypes = {
//     apparel: ApparelChip,
//     model: ModelChip,
//     background: BackgroundChip,
//     prompt: PromptChip,
// };

// export const FormChip = <T extends keyof typeof chipTypes>(props: {
//     type: T;
//     state: React.ComponentProps<(typeof chipTypes)[T]>['state'];
// }) => {};

export const BarChips = (props: Props) => {
    const { file, age, gender, model, background, prompt } = props.state;
    return (
        <>
            {file && <ApparelChip state={{ file }} />}
            {model && age && gender && <ModelChip state={{ model, age, gender }} />}
            {background && <BackgroundChip state={{ background }} />}
            {prompt && <PromptChip state={{ prompt }} />}
        </>
    );
};

export const BarChipsT2I = (props: Props) => {
    const { file, age, gender, model, background, prompt } = props.state;
    return (
        <>
            {prompt && <PromptChip state={{ prompt }} />}
        </>
    );
};
