import { styled } from "@mui/material/styles";

export const LoadingContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const LoadingView = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
`