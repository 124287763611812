import { Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Card: styled(Card)`
        padding: 32px;
    `,
    CardContent: styled(CardContent)`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    `,
    Divider: styled(Divider)`
        margin-bottom: 16px;
        margin-top: 16px;
        width: 100%;
    `,
    Header: styled('div')`
        display: flex;
        flex-direction: row;
        gap: 16px;
    `,
    Icon: styled('img')`
        height: 32px;
        width: 32px;
    `,
    Section: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 16px;
        padding-top: 16px;
        width: 100%;
    `,
    Step: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,
    Row: styled('div')`
        display: flex;
        flex-direction: row;
        gap: 8px;
    `,
};

export default style;
