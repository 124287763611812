import auth from '../../../api/auth';
import createHautechSDK from '@hautechai/web-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Props } from './types';
import { v4 as uuid } from 'uuid';

const inputImages = [
    'https://cdn.preview.dev.hautech.ai/0ea6c5aa-ae44-4a95-b738-b46e340502a2.jpg',
    'https://cdn.preview.dev.hautech.ai/8831ce81-b305-40f7-aee2-c0c72ac78a63.jpg',
];

const useLogic = (props: Props) => {
    const [inputImageIndex, setInputImageIndex] = useState(0);
    const [outputImages, setOutputImages] = useState<string[]>([]);

    const [prompt, setPrompt] = useState('Beatutiful 25-years-old latina woman');
    const [promptEditing, setPromptEditing] = useState(false);
    const [newPrompt, setNewPrompt] = useState(prompt);

    const editPrompt = useCallback(() => setPromptEditing(true), [prompt]);
    const onPromptChange = useCallback((event: any) => setNewPrompt(event.target.value), []);
    const savePrompt = useCallback(() => {
        setPrompt(newPrompt);
        setPromptEditing(false);
    }, [newPrompt]);

    const widget = useMemo(() => {
        const sdk = createHautechSDK({
            //@ts-ignore
            environment: process.env.REACT_APP_ENV_NAME ?? 'local',
            token: auth.token!,
        });
        return sdk.widgets.basic({
            input: { image: { url: inputImages[inputImageIndex] }, prompt, sessionId: uuid() },
            output: {
                image: (url: string) => setOutputImages((images) => [...images, url]),
            },
        });
    }, []);

    useEffect(() => {
        const widgetContainer = document.getElementById('widgetContainer');
        if ((widgetContainer?.children.length ?? 0) > 0) return;

        widgetContainer?.appendChild(widget.iframe);
    }, []);

    useEffect(() => {
        const url = inputImages[inputImageIndex];
        widget.update({ input: { image: { url }, prompt, sessionId: uuid() } });
    }, [inputImageIndex, prompt]);

    return {
        editPrompt,
        inputImageIndex,
        inputImages,
        newPrompt,
        onPromptChange,
        outputImages,
        prompt,
        promptEditing,
        savePrompt,
        setInputImageIndex,
    };
};

export default useLogic;
