import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    BackButtonContainer: styled('div')`
        align-items: center;
        border: 1px solid ${({ theme }) => theme.palette.divider};
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 48px;
        justify-content: center;
        width: 48px;

        &:hover {
            background-color: ${({ theme }) => theme.palette.action.hover};
        }
    `,
    Container: styled('div')`
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 24px;
        justify-content: space-between;
        width: 100%;
    `,
    Data: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
        overflow-y: scroll;
    `,
    Image: styled('img')<{ maxWidth?: number; maxHeight?: number }>`
        max-width: ${({ maxWidth }) => maxWidth ?? 100}px;
        max-height: ${({ maxHeight }) => maxHeight ?? 100}px;
        object-fit: contain;
    `,
    ImageContainer: styled('div')`
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
    `,
    ImageSection: styled('div')`
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: center;
        align-items: start;
    `,
    LoaderContainer: styled('div')`
        align-items: center;
        bottom: 0px;
        display: flex;
        justify-content: center;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
    `,
    PinButtonContainer: styled('div')`
        position: absolute;
        right: 4px;
        top: 4px;
    `,
};

export default style;
