import { Typography } from '@mui/material';
import { FormMode } from '../types';
import Actions from './Actions';
import useLogic from './logic';
import ModeSelection from './ModeSelection';
import * as S from './style';
import TabSwitcher from './TabSwitcher';
import type { Props } from './types';
import TabContent from './TabContent';

const Form = (props: Props) => {
    const { formRef } = useLogic(props);
    return (
        <S.Container ref={formRef}>
            <S.TabsContainer>
                <S.Header>
                    <img src="/icons/ai.svg" />
                    <Typography variant="body2">Create a perfect photoshoot</Typography>
                </S.Header>
                {props.state.file && props.mode===FormMode.apparelToImage && <TabSwitcher onChange={props.setTab} tab={props.tab} state={props.state} />}
                {props.mode === FormMode.selection && <ModeSelection setTab={props.setTab} setMode={props.setMode} />}
                {props.mode !== FormMode.selection && (
                    <TabContent
                        onChangeState={props.onChangeState}
                        state={props.state}
                        tab={props.tab}
                        onChangeTab={props.setTab}
                        mode={props.mode}
                        setMode={props.setMode}
                    />
                )}
            </S.TabsContainer>
            {props.mode !== FormMode.selection && (
                <Actions
                    canGenerate={props.canGenerate}
                    onGenerate={props.onGenerate}
                    onReset={props.onReset}
                    loading={props.loading}
                />
            )}
        </S.Container>
    );
};

export default Form;
