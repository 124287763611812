import { Button, IconButton, Typography } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { MaterialSymbol } from 'react-material-symbols';

const Example = (props: Props) => {
    const {
        editPrompt,
        inputImageIndex,
        inputImages,
        newPrompt,
        onPromptChange,
        outputImages,
        prompt,
        promptEditing,
        savePrompt,
        setInputImageIndex,
    } = useLogic(props);
    const renderInputImage = (url: string, index: number) => (
        <S.ImageContainer key={'input' + index}>
            <S.Image src={url} />
            <Button disabled={index === inputImageIndex} onClick={() => setInputImageIndex(index)} variant="outlined">
                Use this image
            </Button>
        </S.ImageContainer>
    );
    const renderOutputImage = (url: string, index: number) => (
        <S.ImageContainer key={'output-' + index}>
            <S.Image src={url} />
        </S.ImageContainer>
    );

    const renderPrompt = () => (
        <S.PromptContainer>
            {promptEditing ? (
                <>
                    <S.PromptInput onChange={onPromptChange} value={newPrompt} />
                    <Button onClick={savePrompt} variant="outlined">
                        Save
                    </Button>
                </>
            ) : (
                <>
                    <S.Prompt variant="body2">
                        <b>Prompt:</b> {prompt}
                    </S.Prompt>
                    <IconButton onClick={editPrompt}>
                        <MaterialSymbol icon="edit" size={24} />
                    </IconButton>
                </>
            )}
        </S.PromptContainer>
    );

    return (
        <S.Container>
            <S.ImagesSection>
                <Typography variant="body1">Input images</Typography>
                <S.Images>{inputImages.map(renderInputImage)}</S.Images>
                {renderPrompt()}
            </S.ImagesSection>
            <S.WidgetContainer id="widgetContainer" />
            <S.ImagesSection>
                <Typography variant="body1">Output images</Typography>
                <S.Images>{outputImages.map(renderOutputImage)}</S.Images>
            </S.ImagesSection>
        </S.Container>
    );
};

export default Example;
