import { Button, Slider, TextField } from '@mui/material';
import { Props } from './types';
import useLogic from './logic';
import S from './style';

const MaskEditorActions = (props: Props) => {
    const { brushSize, canSubmit, handleBrushSizeChange, handleSubmit, prompt, setPrompt, strength, handleStrengthChange } =
        useLogic(props);
    return (
        <S.Container>
            <S.Row>
                <div>Brush size:</div>
                <Slider aria-label="Size" value={brushSize} onChange={handleBrushSizeChange} min={10} max={150} />
            </S.Row>
            <S.Row>
                <div>Strength:</div>
                <Slider aria-label="Size" value={strength} onChange={handleStrengthChange} min={0} max={100} />
            </S.Row>
            <S.Row>
                <div>Prompt:</div>
                <TextField multiline rows={3} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </S.Row>
            <S.Row>
                <Button
                    onClick={() => {
                        props.enableMaskEditor(false);
                    }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button disabled={!canSubmit} onClick={handleSubmit} variant="outlined">
                    Confirm
                </Button>
            </S.Row>
        </S.Container>
    );
};

export default MaskEditorActions;
