import { useCallback, useMemo } from 'react';
import api from '../../api';
import type { Props } from './types';
import { useParams } from 'react-router-dom';

const useLogic = (props: Props) => {
    let { id: experimentId } = useParams();

    const comparisonAPI = api.comparison.useComparison(experimentId!);

    const onSelect = useCallback(
        async (index: number) => {
            if (!comparisonAPI.pair) return;

            const options = [comparisonAPI.pair.firstOutput.id, comparisonAPI.pair.secondOutput.id];
            await comparisonAPI.select({
                pairId: comparisonAPI.pair.id,
                selectedOutputId: options[index],
            });
            await comparisonAPI.pairRefetch();
        },
        [comparisonAPI.pair, comparisonAPI.select, comparisonAPI.pairRefetch],
    );

    const onClickFirstOutput = useCallback(() => onSelect(0), [onSelect]);
    const onClickSecondOutput = useCallback(() => onSelect(1), [onSelect]);

    return {
        loading: comparisonAPI.pairFetching,
        onClickFirstOutput,
        onClickSecondOutput,
        pair: comparisonAPI.pair,
        selecting: comparisonAPI.selectLoading,
    };
};

export default useLogic;
