import { MutableRefObject } from 'react';

export type FormTab = 'advanced' | 'apparel' | 'background' | 'model' | 'photo' | 'prompt';

export enum FormMode {
    apparelToImage = 'apparel-to-image',
    photoEditor = 'photo-editor',
    selection = 'selection',
    textToImage = 'text-to-image',
}

export type FormState = {
    age?: string;
    angle?: string;
    aspectRatio?: string;
    background?: string;
    model?: string;
    file?: File;
    fileUrl?: string;
    gender?: string;
    hairColor?: string;
    photoType?: string;
    prompt?: string;
    seed?: number;
    skinTone?: string;
    version?: string;
};

export type GenerateController = {
    open: () => void;
};

export interface Props {
    controllerRef?: MutableRefObject<GenerateController>;
}
