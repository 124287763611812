import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ConnectCallback = () => {
    const navigate = useNavigate();
    const [search] = useSearchParams();

    useEffect(() => {
        const discordId = search.get('discordId');
        if (!discordId) return;

        localStorage.setItem('connect-discordId', discordId);

        navigate(`/bots`);
    }, [navigate, search]);

    return null;
};

export default ConnectCallback;
