import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { MaterialSymbol } from 'react-material-symbols';
import Masonry from 'react-responsive-masonry';
import { Loader } from '../../components';
import { StackImage } from '../../utils/stack';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';

const Stacks = (props: Props) => {
    const { loading, loadingMore, onClickDownload, onScroll, onSelectStack, stacksImages } = useLogic(props);
    if (loading) return <Loader />;

    const renderStack = (image: StackImage) => (
        <S.ImageContainer key={image.stackId}>
            {!!image.url || !!image.backgroundUrl ? (
                <>
                    <S.Image onClick={() => onSelectStack(image.stackId)} src={image.url ?? image.backgroundUrl} />
                    <S.ImageBar>
                        <Typography variant="body2">{moment(image.updatedAt).fromNow()}</Typography>
                        <S.ImageButtons>
                            {/* <Tooltip title={image.stack.pinned ? 'Unpin' : 'Pin'}>
                                <IconButton onClick={() => onClickPin(image.stackId)}>
                                    {image.stack.pinned ? <KeepOffIcon color="gray" /> : <KeepIcon color="gray" />}
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Download">
                                <IconButton onClick={() => onClickDownload(image.stackId)}>
                                    <MaterialSymbol icon="download" size={24} />
                                </IconButton>
                            </Tooltip>
                        </S.ImageButtons>
                    </S.ImageBar>
                    {image.loading && (
                        <S.LoaderContainer>
                            <CircularProgress size={24} />
                        </S.LoaderContainer>
                    )}
                </>
            ) : (
                <S.ImageSkeleton />
            )}
        </S.ImageContainer>
    );

    const renderSkeleton = () => (
        <S.ImageContainer>
            <S.ImageSkeleton />
        </S.ImageContainer>
    );

    if (stacksImages.length === 0)
        return (
            <S.Container>
                <S.EmptyState>
                    <S.EmptyStateImage src="/empty-state.png" />
                    <Typography variant="body2">No images here yet. Start creating.</Typography>
                    {/* <Button onClick={onClickStart} variant="contained">
                        Start creating
                    </Button> */}
                </S.EmptyState>
            </S.Container>
        );

    return (
        <>
            <S.Container onScroll={onScroll}>
                <Masonry columnsCount={4}>
                    {stacksImages.map(renderStack)}
                    {loadingMore && (
                        <>
                            {renderSkeleton()}
                            {renderSkeleton()}
                            {renderSkeleton()}
                            {renderSkeleton()}
                            {renderSkeleton()}
                        </>
                    )}
                </Masonry>
            </S.Container>
        </>
    );
};

export default Stacks;
