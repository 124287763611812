import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    BarChipsContainer: styled('div')`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
    `,
    Button: styled(Button)`
        display: flex;
        flex-direction: row;
        gap: 12px;
    `,
    Buttons: styled('div')`
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
        width: 100%;
    `,
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,
    Field: styled('div')`
        align-items: center;
        display: flex;
        gap: 8px;
        justify-content: space-between;
    `,
    FieldLabel: styled('div')`
        font-weight: bold;
    `,
    Fields: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    `,
    FieldValue: styled('div')`
        font-weight: normal;
    `,
    PinIcon: styled('img')`
        height: 24px;
        tint-color: ${({ theme }) => theme.palette.primary.main};
        width: 24px;
    `,
    Prompt: styled('div')``,
    PromptContainer: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `,
    Section: styled('div')`
        background-color: #efefef;
        border-radius: 16px;
        display: flex;
        padding: 16px;
        width: 384px;
    `,
};

export default style;
