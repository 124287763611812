import { Typography } from '@mui/material';
import useLogic from './logic';
import * as S from './style';
import type { Props } from './types';

const ImageSelector = (props: Props) => {
    const {} = useLogic(props);

    return (
        <S.Container>
            {props.title && (
                <Typography color="primary" variant="body2">
                    {props.title}
                </Typography>
            )}
            <S.Images>
                {props.optional && (
                    <S.Placeholder
                        onClick={() => props.onChange(undefined)}
                        selected={props.value === undefined}
                        size={props.size}
                    >
                        empty selection
                    </S.Placeholder>
                )}
                {props.options.map((option) => (
                    <S.Image
                        onClick={() => props.onChange(option.id)}
                        key={option.id}
                        imageUrl={option.url}
                        selected={props.value === option.id}
                        size={props.size}
                    />
                ))}
            </S.Images>
        </S.Container>
    );
};

export default ImageSelector;
