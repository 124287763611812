import { useCallback, useMemo, useRef } from 'react';
import api from '../../api';
import { GenerateController } from '../../components/Generate/types';
import useWebsocketOperationUpdater from '../../hooks/useWebsocketOperationUpdater';
import utils from '../../utils';
import type { Props } from './types';
import { useNavigate } from 'react-router-dom';

const useLogic = (props: Props) => {
    const navigate = useNavigate();
    useWebsocketOperationUpdater();

    const controllerRef = useRef<GenerateController>({ open: () => {} });

    const stacksAPI = api.stack.useStacks();
    const stacksImages = useMemo(() => stacksAPI.list?.map(utils.stack.getStackImage) ?? [], [stacksAPI.list]);

    const onClickDownload = useCallback(
        async (stackId: number) => {
            const stack = stacksAPI.list.find((stack) => stack.id === stackId);
            if (!stack) return;

            const stackImage = utils.stack.getStackImage(stack);
            if (!stackImage?.url) return;

            await utils.download.image(stackImage.url);
        },
        [stacksAPI.list],
    );
    const onClickPin = useCallback(async (stackId: number) => {
        await stacksAPI.pin(stackId);
    }, []);
    const onClickStart = useCallback(() => {
        if (controllerRef?.current) controllerRef.current.open();
    }, [controllerRef]);

    const onSelectStack = useCallback(
        (stackId: number) => {
            const stack = stacksAPI.list?.find((stack) => stack.id === stackId);
            if (!stack) return;

            const stackImage = utils.stack.getStackImage(stack);
            if (!stackImage.backgroundUrl && !stackImage.url) return;

            console.log('stackId', stackId);
            navigate(`/view/${stackId}`);
        },
        [stacksAPI.list],
    );

    const onScroll = useCallback(
        (event: any) => {
            if (!stacksAPI.listHasMore || stacksAPI.listQuery.isFetching) return;

            const { scrollTop, clientHeight, scrollHeight } = event.target;
            if (scrollTop + clientHeight >= scrollHeight - 100) stacksAPI.listFetchMore();
        },
        [stacksAPI.listHasMore, stacksAPI.listQuery.isFetching, stacksAPI.listFetchMore],
    );

    return {
        controllerRef,
        loading: stacksAPI.listLoading,
        loadingMore: stacksAPI.listQuery.isFetching,
        onClickDownload,
        onClickPin,
        onClickStart,
        onScroll,
        onSelectStack,
        stacksImages,
    };
};

export default useLogic;
