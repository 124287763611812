import { useCallback, useMemo, useState } from 'react';
import type { Props } from './types';

const useLogic = (props: Props) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const onClickImage = useCallback((event: any) => {
        setLightboxOpen(true);
        if (props.onClick) props.onClick(event);
    }, []);
    const onCloseLightbox = useCallback(() => setLightboxOpen(false), []);

    const slides = useMemo(() => [{ src: props.src! }], [props.src]);
    return {
        lightboxOpen,
        onClickImage,
        onCloseLightbox,
        slides,
    };
};

export default useLogic;
