import { Avatar, Chip, Typography } from '@mui/material';
import { startCase } from 'lodash';
import { FormState } from '../types';
import S from './style';
import type { Props } from './types';
import useLogic from './logic';
import { BarChips, BarChipsT2I } from '../Chips';
import { forwardRef, MouseEventHandler, useCallback } from 'react';

const Bar = (props: Props, ref: any) => {
    const {} = useLogic(props);

    const buttonHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (props.canGenerate) {
            props.onGenerate();
            e.stopPropagation();
        } else {
            props.onOpen();
        }
    };

    return (
        <S.Container ref={ref} onClick={props.onOpen}>
            <S.Preview>
                <img src="/icons/ai.svg" />
                <Typography variant="body2">Create a perfect photoshoot{props.state.file ? ':' : ''}</Typography>
                {props.state.file && props.mode === 'apparel-to-image' && (
                    <>
                        <BarChips state={props.state} />
                    </>
                )}
                {props.mode === 'text-to-image' && (
                    <>
                        <BarChipsT2I state={props.state} />
                    </>
                )}
            </S.Preview>
            <S.Button onClick={buttonHandler} variant="contained" disabled={props.loading}>
                {!props.loading ? 'Create' : 'Creating'}
            </S.Button>
        </S.Container>
    );
};

export default forwardRef(Bar);
