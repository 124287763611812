import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const discordId = localStorage.getItem('connect-discordId');
        setTimeout(() => navigate(!!discordId ? '/bots' : '/'), 500);
    }, []);

    return null;
};

export default AuthCallback;
