import { styled } from '@mui/material/styles';
import { LightboxImage } from '../../components';
import { Button } from '@mui/material';

const style = {
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 24px;
        justify-content: center;
    `,
    EmptyState: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: center;
    `,
    EmptyStateTitle: styled('h6')`
        color: ${({ theme }) => theme.palette.text.secondary};
        font-size: 32px;
        font-weight: bold;
    `,
    InputContainer: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
    `,
    InputImage: styled(LightboxImage)`
        height: 224px;
        width: auto;

        @media (max-width: 640px) {
            height: 128px;
        }
    `,
    InputPrompt: styled('div')`
        font-size: 24px;
    `,
    OutputButton: styled(Button)`
        background-color: ${({ disabled, theme }) =>
            disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main};
    `,
    OutputContainer: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 12px;
    `,
    OutputImage: styled(LightboxImage)`
        height: 224px;
        width: auto;

        @media (max-width: 640px) {
            height: 128px;
        }
    `,
    Outputs: styled('div')`
        display: flex;
        flex-direction: row;
        gap: 32px;

        @media (max-width: 640px) {
            flex-direction: column;
        }
    `,
};

export default style;
