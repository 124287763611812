import { CircularProgress } from '@mui/material';
import { PropsWithChildren, useEffect, useRef } from 'react';
import Actions from './Actions';
import Details from './Details';
import useLogic from './logic';
import MaskEditor from './MaskEditor';
import S from './style';
import type { Props } from './types';

const ImageSection = (
    props: PropsWithChildren<{ onChangeSize?: (size: { width: number; height: number }) => void }>,
) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (ref.current) {
                props.onChangeSize?.({ width: ref.current.clientWidth, height: ref.current.clientHeight });
            }
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    return <S.ImageSection ref={ref}>{props.children}</S.ImageSection>;
};

const StackEditor = (props: Props) => {
    const {
        image, //
        imageSize,
        onClickPin,
        onImageLoad,
        stack,
        isMaskEditorEnabled,
        enableMaskEditor,
        maskEditorRef,
        handleClose,
        containerSize,
        setContainerSize,
    } = useLogic(props);

    if (!stack) return null;

    return (
        <S.Container>
            <S.BackButtonContainer onClick={handleClose}>
                <img src="/icons/arrow_back.svg" />
            </S.BackButtonContainer>
            <ImageSection onChangeSize={setContainerSize}>
                <S.ImageContainer>
                    {/* <S.PinButtonContainer>
                        <Tooltip title={stack.pinned ? 'Unpin' : 'Pin'}>
                            <IconButton onClick={onClickPin}>
                                {stack.pinned ? <KeepOffIcon color="lightgray" /> : <KeepIcon color="lightgray" />}
                            </IconButton>
                        </Tooltip>
                    </S.PinButtonContainer> */}
                    {isMaskEditorEnabled && imageSize ? (
                        <MaskEditor imageSize={imageSize} maskEditorRef={maskEditorRef} />
                    ) : null}
                    <S.Image
                        onLoad={onImageLoad}
                        src={image.url ?? image.backgroundUrl}
                        maxWidth={containerSize?.width}
                        maxHeight={containerSize?.height}
                    />
                    {image.loading && (
                        <S.LoaderContainer>
                            <CircularProgress size={32} />
                        </S.LoaderContainer>
                    )}
                </S.ImageContainer>
            </ImageSection>
            <S.Data>
                <Actions
                    stack={stack}
                    isMaskEditorEnabled={isMaskEditorEnabled}
                    enableMaskEditor={enableMaskEditor}
                    maskEditorRef={maskEditorRef}
                />
                {imageSize && <Details stack={stack} stackImageSize={imageSize} />}
            </S.Data>
        </S.Container>
    );
};

export default StackEditor;
