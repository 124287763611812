import analytics from '../../analytics';
import api from '../../api';
import methods from '../../api/methods';
import type { Props } from './types';
import { useCallback, useEffect, useState } from 'react';

const useLogic = (props: Props) => {
    const subscriptionAPI = api.subscription.useSubscription();
    const subscriptionPlansAPI = api.subscription.usePlans();

    const [referral, setReferral] = useState<string>();

    useEffect(() => {
        setReferral((window as any).Rewardful?.referral);
        window.addEventListener('Rewardful.initialized', function () {
            setReferral((window as any).Rewardful?.referral);
        });
    }, []);

    const isActiveSubscription = () => {
        const status = subscriptionAPI.data?.status === 'active' || subscriptionAPI.data?.status === 'trialing';
        const expiration = subscriptionAPI.data?.periodEnd ?? 0 > Math.floor(Date.now() / 1000);
        return status && expiration;
    };

    const getSubscribeButtonText = useCallback(
        (planId: string) => {
            if (!subscriptionAPI.data?.subscription) {
                if (!subscriptionAPI.data?.isTrialUsed) {
                    return 'Start free trial';
                } else {
                    return 'Subscribe';
                }
            }
            if (subscriptionAPI.data?.status !== 'active' && subscriptionAPI.data?.status !== 'trialing') {
                return 'Subscribe';
            }

            if (subscriptionAPI.data?.subscription === planId) {
                return 'Unsubscribe';
            }

            const planIndex = subscriptionPlansAPI.list?.findIndex((plan) => plan.id === planId) ?? 0;
            const subscriptionIndex =
                subscriptionPlansAPI.list?.findIndex((plan) => plan.id === subscriptionAPI.data?.subscription) ?? 0;

            return planIndex > subscriptionIndex ? 'Upgrade' : 'Downgrade';
        },
        [subscriptionAPI.data, subscriptionPlansAPI.list],
    );

    const onClickCancel = useCallback(
        async (planId: string) => {
            analytics.mixpanel.track('subscriptions_click_cancel', { planId });
            analytics.google.track('subscriptions', 'click_cancel', planId);
            await subscriptionAPI.cancel(planId);
        },
        [subscriptionAPI.cancel],
    );
    const onClickSubscribe = useCallback(
        async (planId: string) => {
            const isActive = isActiveSubscription();
            if (isActive && subscriptionAPI.data?.subscription === planId) {
                onClickCancel(planId);
                return;
            }

            const event = isActive ? 'subscriptions_click_change' : 'subscriptions_click_subscribe';

            analytics.mixpanel.track(event, { planId });
            analytics.google.track('subscriptions', event.split('_').slice(1, 3).join('_'), planId);

            if (!!isActive) {
                await subscriptionAPI.change(planId);
                return;
            }

            const subscriptionUrl = await methods.subscription.create(planId, referral);
            window.location.href = subscriptionUrl;
        },
        [subscriptionAPI.data, subscriptionAPI.change, referral],
    );

    return {
        buttonsLoading: subscriptionAPI.cancel.mutation.isPending || subscriptionAPI.change.mutation.isPending,
        getSubscribeButtonText,
        loading: subscriptionPlansAPI.listLoading || subscriptionAPI.dataLoading,
        onClickCancel,
        onClickSubscribe,
        plans: subscriptionPlansAPI.list,
        subscription: subscriptionAPI.data,
    };
};

export default useLogic;
