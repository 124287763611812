import { useCallback, useMemo, useState } from 'react';
import { Props } from './types';
import api from '../../../../../api';

const useLogic = (props: Props) => {
    const fileUploadAPI = api.operation.useFileUpload();

    const [brushSize, setBrushSize] = useState<number>(75);
    const [strength, setStrength] = useState<number>(50);
    const [prompt, setPrompt] = useState<string>('');

    const canSubmit = useMemo(() => prompt.trim().length > 0, [prompt]);

    const handleBrushSizeChange = (event: any, newValue: number | number[]) => {
        setBrushSize(newValue as number);
        props.maskEditorRef.current?.setBrushSize?.(newValue as number);
    };

    const handleStrengthChange = (event: any, newValue: number | number[]) => {
        setStrength(newValue as number);
    };

    const handleSubmit = useCallback(async () => {
        if (!canSubmit) return;

        const mask = await props.maskEditorRef.current?.getData?.();
        if (!mask) return;

        const urls = await fileUploadAPI.getFileUploadDetails();

        props.enableMaskEditor(false);

        await fetch(urls.uploadUrl, {
            body: mask,
            headers: { 'Content-Type': 'image/png' },
            method: 'PUT',
        });

        props.replaceAreaHandler({ prompt, maskUrl: urls.downloadUrl, strength });
    }, [canSubmit, prompt, strength]);

    return {
        brushSize,
        canSubmit,
        handleBrushSizeChange,
        handleSubmit,
        prompt,
        setPrompt,
        strength,
        handleStrengthChange,
    };
};

export default useLogic;
