import Bar from './Bar';
import Form from './Form';
import * as S from './style';
import type { Props } from './types';
import useLogic from './logic';
import { useRef, useState } from 'react';
import { Backdrop, Fade, Popover, Typography } from '@mui/material';

const Generate = (props: Props) => {
    const {
        canGenerate,
        loading,
        onChangeState,
        handleClose,
        onGenerate,
        onOpen,
        onReset,
        //open,
        state,
        tab,
        setTab,
        mode,
        setMode,
        anchorEl,
        setAnchorEl,
    } = useLogic(props);

    const barRef = useRef<HTMLDivElement>(null);

    const [parentWidth, setParentWidth] = useState<number | null>();

    const handleOpen = () => {
        setAnchorEl(barRef.current as any);
        setParentWidth(barRef.current?.getClientRects()[0].width ?? 0);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Bar
                ref={barRef}
                canGenerate={canGenerate}
                onGenerate={onGenerate}
                onOpen={handleOpen}
                state={state}
                loading={loading}
                mode={mode}
            />
            <S.Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                elevation={0}
                TransitionComponent={Fade}
                slotProps={{
                    root: {
                        slots: { backdrop: Backdrop },
                    },
                }}
            >
                <S.FormContainer style={{ width: parentWidth ?? 100 }}>
                    <Form
                        canGenerate={canGenerate}
                        loading={loading}
                        onChangeState={onChangeState}
                        onGenerate={onGenerate}
                        onReset={onReset}
                        state={state}
                        tab={tab}
                        setTab={setTab}
                        mode={mode}
                        setMode={setMode}
                    />
                </S.FormContainer>
            </S.Popover>
        </>
    );
};

export default Generate;
