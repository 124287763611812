import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Container: styled('div')`
        align-items: center;
        display: flex;
        padding-bottom: 16px;
        padding-top: 16px;

        @media (max-width: 1023px) {
            flex-direction: column;
            gap: 16px;
        }
        @media (min-width: 1024px) {
            flex-direction: row;
            gap: 48px;
        }
    `,
    Image: styled('img')`
        height: auto;
        width: 128px;
    `,
    ImageContainer: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,
    Images: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        overfloy-y: scroll;
        width: 448px;
    `,
    ImagesSection: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 1023px) {
        }
        @media (min-width: 1024px) {
            height: 600px;
        }
    `,
    Prompt: styled(Typography)`
        width: 256px;
    `,
    PromptContainer: styled('div')`
        align-items: center;
        border: 1px solid lightgray;
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding: 12px;
    `,
    PromptInput: styled(TextField)``,
    WidgetContainer: styled('div')`
        border: 1px solid lightgray;
        height: 600px;
        width: 400px;
    `,
};

export default style;
